/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";

* {
    font-family: Inter, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    position: relative;
    overflow-x: hidden;
}

footer {
    height: 6.875rem;
    background: var(--neutral-100, #F8F9FB);
}

.colorRow {
    width: 100%;
    height: 0.625rem;
    background: var(--primary-950, #1B4397);
    margin-bottom: 0.875rem;
}

.colText {
    margin: 0.125rem;
    padding: 0px 9.844px 0px 10px;
    color: var(--neutral-950, #6B7B8F);
    font-size: 1rem;
    font-style: normal;
  
}

a.colText {
    text-decoration: none;
}

.cancellationRow {
    display: flex;
    margin-left: 4%;
    flex-wrap: wrap;
}

.contactRow {
    display: flex;
    margin-left: 4%;
    flex-wrap: wrap;
}

.phone {
    display: flex;
    justify-content: flex-end;
    color: var(--gray-950, #151B28);
    text-align: right;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    padding-right: 0.8rem;
}

#contactsLink {
    margin-bottom: 1.2rem;
}

.email {
    display: flex;
    justify-content: flex-end;
    color: var(--gray-950, #151B28);
    text-align: right;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    padding-right: 0.8rem;
}

.cdk-overlay-pane.add-student-modal,
.cdk-overlay-pane.edit-student-modal{
    overflow: auto;
}

select:disabled{
    background-color: var(--bs-secondary-bg) !important;
    opacity: 1;
}